import { required, email, maxLength, helpers, url } from '@vuelidate/validators';

export const useValidation = () => {
  const { t } = useI18n();

  const MAX_STRING_LENGTH = 255;
  const usPostcodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

  const notUndefinedRule = (errorMessage: string) => helpers.withMessage(errorMessage, (v) => v !== undefined);

  const httpsUrl = helpers.withMessage(t('validation.httpsUrl'), (value: string) => {
    if (!value) {
      return true; // Don't validate if empty, use `required` for mandatory check
    }
    return value.startsWith('https://');
  });

  return {
    helpers,
    required: helpers.withMessage(t('validation.required', { attribute: 'This' }), required),
    email: helpers.withMessage(t('validation.invalidEmail'), email),
    maxLength,
    maxStringLength: maxLength(MAX_STRING_LENGTH),
    url: helpers.withMessage(t('validation.invalidUrl'), url),
    httpsUrl,
    postcode: helpers.withMessage(t('validation.invalidPostcode'), (value: string) => {
      if (!value) {
        return true;
      }
      return usPostcodeRegex.test(value);
    }),
    dateLte: (maxDateVal: string, fieldName: string) =>
      helpers.withParams(
        { type: 'dateLte', value: maxDateVal },
        helpers.withMessage(t('validation.mustBeBefore', { fieldName }), (value: string) => {
          if (!maxDateVal) {
            return true;
          }
          return new Date(maxDateVal) >= new Date(value);
        }),
      ),
    notUndefinedRule,
  };
};

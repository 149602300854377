export default class UserActivityError extends Error {
  userActivityId: string;
  constructor(message: string, userActivityId: string) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.userActivityId = userActivityId;
    Error.captureStackTrace(this, this.constructor);
  }
}

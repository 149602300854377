<script lang="ts" setup>
type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type HeadingLevel = HeadingLevels | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface Props {
  size: HeadingLevels;
  styleSize?: HeadingLevels;
  isHidden?: boolean;
  customClasses?: string;
  htmlText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  isHidden: false,
  styleSize: 'h1',
  customClasses: '',
  htmlText: '',
});

const variantClasses = computed<string>(() => {
  return twMerge(
    cva('leading-tight', {
      variants: {
        styleSize: {
          h1: 'font-bold text-4xl lg:text-5xl',
          h2: 'font-bold text-3xl lg:text-4xl',
          h3: 'font-bold text-2xl lg:text-3xl',
          h4: 'font-bold  text-xl lg:text-2xl',
          h5: 'font-semibold text-xl',
          h6: 'font-semibold text-lg',
        },
        isHidden: {
          true: 'sr-only',
        },
      },
    })({
      styleSize: props.styleSize,
      isHidden: props.isHidden,
    }),
    props.customClasses,
  );
});
</script>

<template>
  <component :is="size" :class="variantClasses">
    <!-- eslint-disable-next-line -->
    <div v-if="htmlText" v-html="htmlText" />
    <slot v-else />
  </component>
</template>

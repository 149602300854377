import { useSubscription, type SubscriptionHandler } from '@urql/vue';
import { studentRoleRequestCreatedSubscription, schoolStudentRoleInviteCountsFragment } from '~/graphql/documents/core';
import type { StudentRoleRequestCreatedSubscription } from '~/graphql/generated/graphql';
import { useFragment } from '~/graphql/generated/fragment-masking';

export const useStudentRequest = (currentSchoolId: Ref<string | null | undefined>) => {
  const newStudentRequestId = ref<string | undefined>(undefined);
  const handleSubscription: SubscriptionHandler<StudentRoleRequestCreatedSubscription, never[] | undefined> = (messages, response) => {
    if (!response.studentRoleRequestCreated?.deletedAt) {
      newStudentRequestId.value = response.studentRoleRequestCreated?.id;
    }
    return messages;
  };

  useSubscription(
    {
      query: studentRoleRequestCreatedSubscription,
      variables: computed(() => ({ schoolId: currentSchoolId.value })),
    },
    handleSubscription,
  );

  const clearNewStudentRequest = () => {
    newStudentRequestId.value = undefined;
  };

  return { newStudentRequestId, clearNewStudentRequest };
};

export const useSchoolInviteCount = () => {
  const { schoolTeacherRoles, currentSchoolId } = useAuth();

  const schoolRequestsCount = computed(() => {
    const school = schoolTeacherRoles.value.find((role) => role.school?.id === currentSchoolId.value)?.school;
    if (!school) {
      return 0;
    }

    return useFragment(schoolStudentRoleInviteCountsFragment, school)?.studentRoleRequestsCount || 0;
  });

  const schoolInvitesCount = computed(() => {
    const school = schoolTeacherRoles.value.find((role) => role.school?.id === currentSchoolId.value)?.school;
    if (!school) {
      return 0;
    }

    return useFragment(schoolStudentRoleInviteCountsFragment, school)?.studentRoleInvitesCount || 0;
  });

  return { schoolRequestsCount, schoolInvitesCount };
};

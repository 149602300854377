import { useAuthStore } from '~/stores/auth';

export default defineNuxtPlugin({
  name: 'auth',
  dependsOn: [],
  setup: async () => {
    const authStore = useAuthStore();
    await authStore.checkForAuthUser();
  },
});

import type { RouterOptions } from '@nuxt/schema';

export default <RouterOptions>{
  routes: (_routes) => {
    const { isManageDomain } = useDomainConfig();

    if (isManageDomain) {
      return _routes
        .filter((r) => r.path.startsWith('/manage'))
        .map((r) => {
          return {
            ...r,
            path: r.path.replace('/manage', ''),
          };
        });
    }

    return _routes
      .filter((r) => r.path.startsWith('/plan'))
      .map((r) => {
        return {
          ...r,
          path: r.path.replace('/plan', ''),
        };
      });
  },
};

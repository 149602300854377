<script lang="ts" setup>
interface Props {
  position: 'top' | 'bottom' | 'top-right' | 'top-left' | 'bottom-left';
  intensity?: 'default' | 'darker';
  twGradientClasses: string; // e.g. from-primary-500 to-secondary-500
}

const props = withDefaults(defineProps<Props>(), {
  intensity: 'default',
});

const gradientSrc = computed<string>(() => {
  let src = `layouts/background/gradient-${props.position}`;
  if (props.intensity !== 'default') {
    src += `-${props.intensity}`;
  }

  return src;
});

const gradientClasses = computed(() => {
  return twMerge(
    cva('absolute pointer-events-none -z-10', {
      variants: {
        position: {
          top: 'top-0 left-0 right-0',
          bottom: 'bottom-0 left-0 right-0',
          'top-right': 'right-0 top-[-35%] sm:top-0',
          'bottom-left': 'left-0 bottom-[-35%] sm:bottom-0',
          'top-left': 'left-0 sm:top-0 top-[-10%]',
        },
      },
    })({
      position: props.position,
    }),
    props.twGradientClasses,
  );
});
</script>

<template>
  <UIAssetImage :class="gradientClasses" :path="gradientSrc" />
</template>

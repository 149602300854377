import type { CombinedError } from '@urql/core';

export default class UnexpectedGraphqlError extends Error {
  graphqlError?: CombinedError;
  constructor(message: string, graphqlError?: CombinedError) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.graphqlError = graphqlError;
    Error.captureStackTrace(this, this.constructor);
  }
}

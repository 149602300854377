<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed } from 'vue';

interface Props {
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg';
  variant?: 'gradient' | 'basic';
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  variant: 'gradient',
});

const variantClasses = computed<string>(() => {
  return twMerge(
    cva('inline-block box-border', {
      variants: {
        variant: {
          gradient: 'animate-rotation',
          basic: 'animate-loading border-[3px] border-b-transparent rounded-full',
        },
        size: {
          '2xs': 'h-2 w-2 p-[1px]',
          xs: 'h-3 w-3 p-[2px]',
          sm: 'h-6 w-6 p-1',
          md: 'h-12 w-12 p-2',
          lg: 'h-24 w-24 p-4',
        },
      },
      compoundVariants: [
        {
          variant: 'basic',
          size: 'sm',
          class: 'h-6 w-6 p-2',
        },
        {
          variant: 'basic',
          size: 'md',
          class: 'h-6 w-6 p-1',
        },
        {
          variant: 'basic',
          size: 'lg',
          class: 'h-12 w-12 p-4',
        },
      ],
    })({
      size: props.size,
      variant: props.variant,
    }),
  );
});
</script>

<template>
  <div v-if="variant === 'gradient'" :class="variantClasses" aria-label="loading" class="gradient-loader" role="status" />
  <div v-else :class="variantClasses" aria-label="loading" class="" role="status" />
</template>

import type { SubscriptionForwarder } from '@urql/core';
import type { ExecutionResult } from 'graphql';
import Pusher from 'pusher-js';

export const useSubscriptionExchange = () => {
  const { apiUrl, clusterDomain } = useDomainConfig();
  const config = useRuntimeConfig();

  const forwardSubscriptionToPusher: SubscriptionForwarder = (operation) => {
    const pusherClient = new Pusher(config.public.pusherAppKey, {
      cluster: 'us2',
      wsHost: `sockets.${clusterDomain}`,
      wsPort: config.public.useHttp ? 6001 : 443,
      forceTLS: !config.public.useHttp,
      enabledTransports: ['wss', 'ws'],
      disableStats: true,
      authEndpoint: `${apiUrl}/graphql/subscriptions/auth`,
    });

    return {
      subscribe: ({ next, error, complete }) => {
        const fetchBody = JSON.stringify({
          query: operation.query,
          variables: operation.variables,
          operationName: operation.operationName,
        });

        let pusherChannelName: string | undefined | null;
        fetch(`${apiUrl}/graphql`, {
          method: 'POST',
          credentials: 'include',
          body: fetchBody,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            origin: clusterDomain,
          },
        })
          // eslint-disable-next-line require-await
          .then(async (response) => {
            return response.json();
          })
          .then((result) => {
            pusherChannelName = result?.extensions?.lighthouse_subscriptions.channel;
            if (!pusherChannelName) {
              return;
            }

            const pusherChannel = pusherClient.subscribe(pusherChannelName);
            pusherChannel.bind('lighthouse-subscription', (payload: { more: boolean; result: ExecutionResult }) => {
              const { result } = payload;
              if (result) {
                if (result.errors) {
                  error(result.errors);
                } else {
                  next(result);
                }
              }

              if (!payload.more) {
                complete();
              }
            });

            // forward the initial result to urql
            next(result);
          })
          .catch((err: unknown) => {
            error(err);
          });

        return {
          unsubscribe: () => {
            // When requested by urql, disconnect from this channel
            if (pusherChannelName) {
              pusherClient.unsubscribe(pusherChannelName);
            }
          },
        };
      },
    };
  };

  return { forwardSubscriptionToPusher };
};

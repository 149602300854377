<script lang="ts" setup>
interface Props {
  path: string;
}
const props = defineProps<Props>();

const assetPath = computed(() => {
  return `images/${props.path}.svg`;
});
</script>

<template>
  <UIAsset :path="assetPath" />
</template>

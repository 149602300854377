<script lang="ts" setup>
interface Props {
  path: string;
}

const props = defineProps<Props>();

const assetHtml = ref<string>('');

const getAssetHtml = async (path: string): Promise<void> => {
  try {
    const iconsImport = <Record<string, () => Promise<string>>>import.meta.glob('assets/**/**', {
      query: '?raw',
      import: 'default',
    });

    const importFn = iconsImport[`/assets/${path}`];

    if (importFn) {
      assetHtml.value = await importFn();
    }
  } catch (e) {
    //
  }
};

watch(
  () => props.path,
  (newPath) => {
    void getAssetHtml(newPath);
  },
  { immediate: true },
);
</script>

<template>
  <UIHtml :html="assetHtml" />
</template>

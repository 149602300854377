import routerOptions0 from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_g6uerw3oftns6hjyou24sx2aiq/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}
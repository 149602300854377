<script setup lang="ts">
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed } from 'vue';

const { switchToSchool, currentSchoolId, schoolTeacherRoles } = useAuth();
const configStore = useConfigStore();

const clusterTeacherRoles = computed(() => {
  return schoolTeacherRoles.value.filter((r) => r.school?.clusterId === configStore.cluster?.id);
});

const checkboxClasses = (selected: boolean) => {
  return twMerge(
    cva('rounded-full p-1 border border-grey-400 flex items-center justify-center bg-white w-6 h-6', {
      variants: {
        selected: {
          true: 'border-green-400 text-green-400 bg-green-50',
        },
      },
    })({ selected }),
  );
};

const rowClasses = (selected: boolean, isLast: boolean) => {
  return twMerge(
    cva(
      'w-full flex items-center justify-between space-x-5 p-3 hover:bg-primary-50 focus:bg-primary-50 bg-grey-50 break-words font-medium text-left',
      {
        variants: {
          selected: {
            true: 'bg-primary-50 bg-opacity-50',
          },
          isLast: {
            true: 'rounded-b-lg',
          },
        },
      },
    )({ selected, isLast }),
  );
};

const isSelectedSchool = (schoolId?: string) => currentSchoolId.value === schoolId;
const selectSchool = (close: () => void, id?: string) => {
  if (!id) {
    return;
  }
  void switchToSchool(id);
  close();
};

const currentSchool = computed(() => clusterTeacherRoles.value.find((r) => r.school?.id === currentSchoolId.value)?.school);
</script>

<template>
  <div class="relative">
    <template v-if="clusterTeacherRoles.length > 1">
      <UIPopover>
        <template #anchor="{ close }">
          <button
            type="button"
            class="flex items-center text-primary-500"
            :aria-label="$t('general.switchSchool.label')"
            data-testid="change-school"
            @keydown.esc="close()"
          >
            <UIHeading size="h1" styleSize="h4" customClasses="sm:text-2xl text-lg"> {{ currentSchool?.name || '' }}</UIHeading>
            <UIAssetIcon path="chevron-down-full" size="xs" class="flex ml-1 fill-primary-500" />
          </button>
        </template>
        <template #content="{ close }">
          <UICard role="dialog" aria-modal="true" customClasses="bg-white rounded-lg px-0 py-0 w-56" @keydown.esc="close()">
            <template #default>
              <div class="flex items-center w-full p-3">
                <div class="font-bold">{{ $t('general.switchSchool.title') }}</div>
              </div>
              <ul>
                <li v-for="(role, index) in clusterTeacherRoles" :key="role.id">
                  <button
                    type="button"
                    :class="rowClasses(isSelectedSchool(role.school?.id), index === clusterTeacherRoles.length - 1)"
                    :aria-label="role.school?.name"
                    @click="selectSchool(close, role.school?.id)"
                  >
                    <span>{{ role.school?.name }}</span>
                    <div :class="checkboxClasses(isSelectedSchool(role.school?.id))">
                      <UIAssetIcon v-if="isSelectedSchool(role.school?.id)" path="check" size="xs" />
                    </div>
                  </button>
                </li>
              </ul>
            </template>
          </UICard>
        </template>
      </UIPopover>
    </template>
    <template v-else>
      <UIHeading size="h1" styleSize="h4" customClasses="sm:text-2xl text-lg"> {{ currentSchool?.name || '' }}</UIHeading>
    </template>
  </div>
</template>

export default defineNuxtRouteMiddleware(async (to) => {
  // Get the authType from meta
  const { authType } = to.meta;

  // Check if authType exists and is an array
  if (authType && !Array.isArray(authType)) {
    throw createError({
      statusCode: 500,
      statusMessage: 'Route Configuration Error',
      message: `Route ${to.path} has invalid authType configuration. Expected array.`,
    });
  }

  const routeAuthType = authType || [AUTH_ROUTE_TYPE_AUTHENTICATED];

  const { isManageDomain } = useDomainConfig();

  const authStore = useAuthStore();

  const loginRedirectRoute = '/login';
  const dashboardRedirectPath = '/dashboard';

  const guards = {
    [AUTH_ROUTE_TYPE_NONE]: {
      check: () => true,
      // eslint-disable-next-line no-empty-function
      failedRedirect: () => {},
    },
    [AUTH_ROUTE_TYPE_AUTHENTICATED]: {
      check: () => {
        const { isLoggedIn, user } = authStore;
        return isManageDomain ? isLoggedIn : isLoggedIn && !user?.isGhostStudent;
      },
      failedRedirect: async () => {
        return await navigateTo(loginRedirectRoute, { replace: true });
      },
    },
    [AUTH_ROUTE_TYPE_GUEST]: {
      check: () => {
        return !authStore.isLoggedIn;
      },
      failedRedirect: async () => {
        return await navigateTo(dashboardRedirectPath, { replace: true });
      },
    },
    [AUTH_ROUTE_TYPE_GHOST]: {
      //TODO: implement isGhostTeacher based on siteType when needed
      check: () => {
        const { isLoggedIn, user } = authStore;
        return isLoggedIn && user?.isGhostStudent;
      },
      failedRedirect: async () => {
        return await navigateTo('/', { replace: true });
      },
    },
  };

  const passesAnyCheck = routeAuthType.some((type) => {
    const guard = guards[type];
    return guard && guard.check();
  });

  if (!passesAnyCheck) {
    const firstGuard = guards[routeAuthType[0]];
    if (firstGuard) {
      return await firstGuard.failedRedirect();
    }
  }
});

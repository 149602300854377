import { datadogLogs } from '@datadog/browser-logs';
import type { RuntimeConfig } from 'nuxt/schema';
import buildInfo from '@/buildInfo';
import { type ErrorHandler } from '@/types/errorHandler';
import { datadogRum } from '@datadog/browser-rum';

class ConsoleErrorHandler implements ErrorHandler {
  logError(error: Error, additionalContext: object = {}) {
    // eslint-disable-next-line no-console
    console.log({
      error,
      additionalContext,
    });
  }
}

class ClientDatadogErrorHandler implements ErrorHandler {
  constructor(config: RuntimeConfig) {
    datadogLogs.init({
      site: 'datadoghq.com',
      clientToken: config.public.datadogClientToken,
      forwardErrorsToLogs: false,
      service: `${config.public.datadogService}-browser`,
      version: buildInfo.build,
      env: config.public.env,
    });
  }

  logError(error: Error, additionalContext: object = {}) {
    datadogLogs.logger.error(error.message, { context: additionalContext }, error);
  }
}

const getErrorHandler = (config: RuntimeConfig) => {
  if (!config.public.datadogEnabled) {
    return new ConsoleErrorHandler();
  }

  return new ClientDatadogErrorHandler(config);
};

const initRum = (config: RuntimeConfig, apiUrl: string) => {
  if (!config.public.datadogEnabled) {
    return;
  }

  datadogRum.init({
    applicationId: config.public.datadogApplicationId,
    clientToken: config.public.datadogClientToken,
    site: 'datadoghq.com',
    service: `${config.public.datadogService}-browser`,
    version: buildInfo.build,
    env: config.public.env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    allowedTracingUrls: [apiUrl],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
};

export default defineNuxtPlugin({
  name: 'errorHandler',
  dependsOn: [],
  setup: () => {
    const { apiUrl } = useDomainConfig();

    const config = useRuntimeConfig();
    const errorHandler = getErrorHandler(config);
    initRum(config, apiUrl);
    const { logError, logErrorMessage } = setupErrorHandling(errorHandler);
    return { provide: { logError, logErrorMessage } };
  },
});

declare module '#app' {
  interface NuxtApp {
    $logError: (error: Error, additionalContext?: object) => void;
    $logErrorMessage: (message: string, additionalContext?: object) => void;
  }
}

declare module '#app' {
  interface NuxtApp {
    $appAssetsUrl: (path: string) => string;
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $appAssetsUrl: (path: string) => string;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  // Access the appAssets URL from the runtime config
  const appAssetsUrl = useRuntimeConfig().public.appAssetsUrl || '';

  // Function to concatenate appAssets URL with the provided string
  const concatAppAssetUrl = (path: string): string => {
    return `${appAssetsUrl}${path}`;
  };

  // Make the function globally available
  nuxtApp.provide('appAssetsUrl', concatAppAssetUrl);
});

import { useMutation } from '@urql/vue';
import { setAnimationsEnabled } from '~/graphql/documents/core';

export const useAccessibilityStore = defineStore('accessibilityStore', () => {
  const { user } = storeToRefs(useAuthStore());

  const animationsEnabled = ref(true);

  const { executeMutation: _setAnimationsEnabled } = useMutation(setAnimationsEnabled);

  watch(
    user,
    (newUser, oldUser) => {
      if (!oldUser && newUser && !newUser.animationsEnabled) {
        animationsEnabled.value = false;
      }
    },
    { immediate: true },
  );

  const saveAnimationSetting = async () => {
    await _setAnimationsEnabled({ animationsEnabled: animationsEnabled.value });
  };

  return { animationsEnabled, saveAnimationSetting };
});

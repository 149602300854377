import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, MotionPathPlugin);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('gsap', gsap);
});

declare module '#app' {
  interface NuxtApp {
    $gsap: typeof gsap;
  }
}

<script setup lang="ts">
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed } from 'vue';

interface Props {
  variant: 'iconmarkLight' | 'iconmarkDark' | 'iconmarkMixed' | 'lockupPrimaryLight' | 'lockupPrimaryDark';
  customClasses?: string;
}

const props = defineProps<Props>();

const configStore = useConfigStore();

const logoClasses = computed<string>(() => {
  return twMerge(
    cva('', {
      variants: {
        variant: {
          iconmarkLight: 'w-10',
          iconmarkDark: 'w-10',
          iconmarkMixed: 'w-10',
          lockupPrimaryLight: 'w-40',
          lockupPrimaryDark: 'w-40',
        },
      },
    })({
      variant: props.variant,
    }),
    props.customClasses,
  );
});
</script>

<template>
  <UIHtml :class="logoClasses" :html="configStore.cluster?.theme.logos[variant] || ''" />
</template>

import {
  deepUserActivityFragment,
  GET_USER_ACTIVITY_BY_IDS,
  SAVE_USER_ACTIVITY_COMPLETE,
  SAVE_USER_ACTIVITY_RESPONSE,
  shallowUserActivityFragment,
  shallowUserActivityGroupFragment,
  SKIP_USER_ACTIVITY,
} from '~/graphql/documents/activities';
import type { ActivityGroupType } from '~/graphql/generated/graphql';
import { useFragment } from '~/graphql/generated/fragment-masking';
import UserActivityError from '~/utils/errors/UserActivityError';

export const useActivityMutations = () => {
  const { $urqlClient } = useNuxtApp();

  const submitting = ref(false);

  const saveUserActivityResponse = async (userActivityId: string, responseKey: string, jsonResponse: string) => {
    const { data, error } = await $urqlClient.mutation(SAVE_USER_ACTIVITY_RESPONSE, {
      userActivityId,
      key: responseKey,
      jsonResponse,
    });

    const userActivity = useFragment(deepUserActivityFragment, data?.activities?.saveResponse?.userActivity);

    if (error || !userActivity) {
      throw new UserActivityError(error?.message || 'unknown', userActivityId);
    }

    return userActivity;
  };

  const completeUserActivity = async (userActivityId: string, jsonPayload: string) => {
    submitting.value = true;
    const { data, error } = await $urqlClient.mutation(SAVE_USER_ACTIVITY_COMPLETE, {
      userActivityId,
      jsonPayload,
    });

    const userActivity = useFragment(deepUserActivityFragment, data?.activities?.completeActivity?.userActivity);

    if (error || !userActivity) {
      throw new UserActivityError(error?.message || 'unknown', userActivityId);
    }

    return userActivity;
  };

  const skipUserActivity = async (userActivityId: string) => {
    submitting.value = true;

    const { data, error } = await $urqlClient.mutation(SKIP_USER_ACTIVITY, {
      userActivityId,
    });

    const userActivity = useFragment(shallowUserActivityFragment, data?.activities?.skipActivity?.userActivity);

    if (error || !userActivity) {
      throw new UserActivityError(error?.message || 'unknown', userActivityId);
    }

    return userActivity;
  };

  return {
    saveUserActivityResponse,
    completeUserActivity,
    skipUserActivity,
    submitting,
  };
};

export const getUserActivity = async (userActivityId: string) => {
  const { $urqlClient } = useNuxtApp();

  const { data, error } = await $urqlClient.query(GET_USER_ACTIVITY_BY_IDS, { ids: userActivityId }, { requestPolicy: 'network-only' });

  if (error || !data?.core.user.self?.userActivities[0]) {
    throw new UserActivityError(error?.message || 'unknown', userActivityId);
  }

  return useFragment(deepUserActivityFragment, data.core.user.self.userActivities[0]);
};

export const useAuthUserActivityGroups = () => {
  const { user } = useAuth();

  const allUserActivityGroups = computed(() => {
    return user.value?.userActivityGroups.map((g) => useFragment(shallowUserActivityGroupFragment, g));
  });

  const currentUserActivityGroup = computed(() => {
    if (!allUserActivityGroups.value) {
      return null;
    }

    const incompleteGroup = allUserActivityGroups.value.find((g) => g.status !== 'complete');
    return incompleteGroup ?? allUserActivityGroups.value[allUserActivityGroups.value.length - 1];
  });

  const currentUserActivities = computed(() => {
    if (!currentUserActivityGroup.value) {
      return [];
    }

    return currentUserActivityGroup.value.userActivities.map((a) => useFragment(shallowUserActivityFragment, a));
  });

  const currentActivityGroupIsVisionBoard = computed((): boolean => {
    return currentUserActivityGroup.value?.activityGroup.type === 'CAREER_VISION_BOARD';
  });

  return {
    currentActivityGroupIsVisionBoard,
    allUserActivityGroups,
    currentUserActivityGroup,
    currentUserActivities,
  };
};

export const useAuthUserActivity = (activityTitle: string) => {
  const { user } = useAuth();

  const allUserActivityGroups = computed(() => {
    return user.value?.userActivityGroups.map((g) => useFragment(shallowUserActivityGroupFragment, g));
  });

  const allUserActivities = computed(() => {
    if (!allUserActivityGroups.value) {
      return [];
    }

    return allUserActivityGroups.value
      .map((ag) => ag.userActivities)
      .reduce((carry, groupUserActivities) => {
        // eslint-disable-next-line
        carry = [...carry, ...groupUserActivities];
        return carry;
      }, [])
      .map((a) => useFragment(shallowUserActivityFragment, a));
  });

  return computed(() => {
    return allUserActivities.value.find((ua) => ua.activity?.title === activityTitle);
  });
};

export const useAuthUserActivityGroup = (activityGroupType: ActivityGroupType) => {
  const { user } = useAuth();

  const allUserActivityGroups = computed(() => {
    return user.value?.userActivityGroups.map((g) => useFragment(shallowUserActivityGroupFragment, g));
  });

  const userActivityGroup = computed(() => {
    if (!allUserActivityGroups.value) {
      return null;
    }
    return allUserActivityGroups.value.find((g) => g.activityGroup.type === activityGroupType);
  });

  const userActivityGroupActivities = computed(() => {
    if (!userActivityGroup.value) {
      return [];
    }

    return userActivityGroup.value.userActivities.map((a) => useFragment(shallowUserActivityFragment, a));
  });

  return {
    userActivityGroup,
    userActivityGroupActivities,
  };
};

import { useConfigStore } from '~/stores/config';

export default defineNuxtPlugin({
  name: 'load-config',
  dependsOn: [],
  setup: async () => {
    const configStore = useConfigStore();

    if (import.meta.server) {
      await configStore.loadConfig();
    }

    useHead({
      style: [
        {
          children: `
            :root {
              ${configStore.cluster?.theme.colors.map((color) => `--${color.id}: ${color.value};`).join(' ')}
            }
          `,
        },
      ],
    });

    const { $i18n } = useNuxtApp();
    $i18n.setLocaleMessage('en-US', configStore.i18nMessages || {});
  },
});

import { updateSiteConfig } from '#imports';
import { useConfigStore } from '~/stores/config';

export default defineNuxtPlugin({
  enforce: 'pre',
  name: 'seo',
  dependsOn: ['load-config'],
  setup() {
    const nuxtApp = useNuxtApp();
    const { siteDomain, isManageDomain } = useDomainConfig();

    const configStore = useConfigStore();

    if (import.meta.server) {
      updateSiteConfig({
        url: `https://${siteDomain}`,
        name: isManageDomain ? configStore.cluster?.manageProductName : configStore.cluster?.planProductName,
        description: isManageDomain ? nuxtApp.$i18n.t('general.manage.description') : nuxtApp.$i18n.t('general.plan.description'),
      });
    }
  },
});

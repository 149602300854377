import revive_payload_client_nQbVkDuf8P from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_g6uerw3oftns6hjyou24sx2aiq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HNEwIAbBHm from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_g6uerw3oftns6hjyou24sx2aiq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IIoW1iSJdX from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_g6uerw3oftns6hjyou24sx2aiq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_CENimRWQJH from "/var/www/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_sass-embe_2ehrry7rzixo72sfaogzwyptni/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import _03_seo_fZau0DgIwP from "/var/www/nuxt/plugins/03.seo.ts";
import payload_client_ijDvqDQ25y from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_g6uerw3oftns6hjyou24sx2aiq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dO8cKFTZBB from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_g6uerw3oftns6hjyou24sx2aiq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aozHmpg0y6 from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_g6uerw3oftns6hjyou24sx2aiq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_c6o4tJvbvA from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_g6uerw3oftns6hjyou24sx2aiq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CNYRYs1K0a from "/var/www/nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.2_typescript@5.5.4_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_n6D1RgQL8f from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_g6uerw3oftns6hjyou24sx2aiq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_vr2q5ZBJZv from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.13.0_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_k22hqgmpapfba57q4okgmgjtve/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_3cjG5l3BxF from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.13.0_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_k22hqgmpapfba57q4okgmgjtve/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_jrqh3Axusq from "/var/www/nuxt/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_sass-e_7n7ya5dl6umuinnhhyziwh2pei/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_QpLbfar6Bt from "/var/www/nuxt/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_sass-e_7n7ya5dl6umuinnhhyziwh2pei/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_hbrMrjDTVV from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.2_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_zd0wSGr56Q from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.2_vue@3.5.12_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _00_datadog_client_oBfMsBvh4D from "/var/www/nuxt/plugins/00.datadog.client.ts";
import _01_urql_b9cmdWz5Xy from "/var/www/nuxt/plugins/01.urql.ts";
import _02_loadConfig_RK9zG7A5iq from "/var/www/nuxt/plugins/02.loadConfig.ts";
import _04_auth_b3V54MNNLz from "/var/www/nuxt/plugins/04.auth.ts";
import _05_analytics_BKRvirLbPf from "/var/www/nuxt/plugins/05.analytics.ts";
import _06_appAssets_dOxy1bcLa6 from "/var/www/nuxt/plugins/06.appAssets.ts";
import gsap_client_YCLjR5XmPz from "/var/www/nuxt/plugins/gsap.client.ts";
export default [
  revive_payload_client_nQbVkDuf8P,
  unhead_HNEwIAbBHm,
  router_IIoW1iSJdX,
  _0_siteConfig_CENimRWQJH,
  _03_seo_fZau0DgIwP,
  payload_client_ijDvqDQ25y,
  navigation_repaint_client_dO8cKFTZBB,
  check_outdated_build_client_aozHmpg0y6,
  chunk_reload_client_c6o4tJvbvA,
  plugin_vue3_CNYRYs1K0a,
  components_plugin_KR1HBZs4kY,
  prefetch_client_n6D1RgQL8f,
  titles_vr2q5ZBJZv,
  defaultsWaitI18n_3cjG5l3BxF,
  siteConfig_jrqh3Axusq,
  inferSeoMetaPlugin_QpLbfar6Bt,
  switch_locale_path_ssr_hbrMrjDTVV,
  i18n_zd0wSGr56Q,
  _00_datadog_client_oBfMsBvh4D,
  _01_urql_b9cmdWz5Xy,
  _02_loadConfig_RK9zG7A5iq,
  _04_auth_b3V54MNNLz,
  _05_analytics_BKRvirLbPf,
  _06_appAssets_dOxy1bcLa6,
  gsap_client_YCLjR5XmPz
]
import type { CombinedError } from '@urql/core';

const getValidationErrorMessages = (validation: Record<string, string>) => {
  const validationErrorKeys = Object.keys(validation);
  if (!validationErrorKeys.length) {
    return null;
  }

  if (!validation[validationErrorKeys[0]]) {
    return null;
  }

  return validation[validationErrorKeys[0]][0];
};

const getErrorMessagesFromCombinedErrors = (errorObject: CombinedError): string[] => {
  return errorObject.graphQLErrors
    .map((e) => {
      if (e.extensions.code) {
        return <string>e.extensions.clientMessage;
      }

      if (e.extensions.validation) {
        return getValidationErrorMessages(<Record<string, string>>e.extensions.validation);
      }

      return null;
    })
    .filter((e): e is string => e !== null);
};

export const getFirstErrorMessageFromCombinedErrors = (t: (key: string) => string, errorObject: CombinedError) => {
  const errorMessages = getErrorMessagesFromCombinedErrors(errorObject);
  if (errorMessages && errorMessages.length) {
    return errorMessages[0];
  }

  return t('general.unexpectedError');
};

export const findGraphqlErrorWithCode = (errorObject: CombinedError, code: string) => {
  return errorObject.graphQLErrors.find((e) => {
    return e.extensions.code === code;
  });
};
